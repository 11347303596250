:root {
  --light-green: #e5fbe5;
  --dark-green: green;
}

body {
  scroll-behavior: smooth;
}

.ant-pagination-total-text {
  margin-right: auto;
  font-weight: 500;
}

.ant-table {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  &-cell {
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.customScroll {
  overflow-y: auto;
}

.customScroll::-webkit-scrollbar {
  background: #cccccc;
  width: 6px;
}

.customScroll::-webkit-scrollbar-track {
  background: #fff;
}

.customScroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 7px;
}

.ant-menu-item-selected,
.ant-menu-submenu-active {
  .ant-menu-submenu-title {
    font-weight: 500;
    color: var(--dark-green);
    background-color: var(--light-green);

    svg {
      color: var(--dark-green);
    }

    &:hover {
      color: var(--dark-green) !important;
      background-color: var(--light-green);
    }
  }

  .ant-menu-submenu-arrow {
    color: var(--dark-green);
    &:hover {
      color: var(--dark-green);
    }
  }
}
